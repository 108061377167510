import axios from 'axios';
import { concat, filter } from 'lodash';
import { Skeleton, Tooltip } from 'antd';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import ExcelJS from 'exceljs';
import {
  EXTRACT_IMAGE_ELEMENTS_FROM_HTML,
  EXTRACT_URL_FROM_IMAGE_ELEMENT
} from '../constants/regex';
import API from '../config/endpoints.config';
import { allowedFileExtensions } from '../constants';

const axiosInstance = axios.create({
  baseURL: API.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('AccessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosInstance };

export const onChangeFilters = (
  item,
  selected,
  filterFromArray,
  selectedFilters
) => {
  let newItems = [];
  if (item == 'all') {
    if (!selected) filterFromArray.map(item => newItems.push(item.key));
    else newItems = [];
  } else
    newItems = selected
      ? filter(selectedFilters, filterKey => {
        return filterKey !== item && filterKey != 'all';
      })
      : concat(selectedFilters, item);
  const filterItems = filterFromArray.filter(
    key => !newItems.includes(key.key)
  );
  if (filterItems.length == 1) {
    if (filterItems[0].key == 'all') {
      newItems.push('all');
    }
  }
  return newItems;
};

export const getSortedList = ((list, sortField) => {
  // sort elements here
  const compare = (a, b) => {
    if (a[sortField]?.toLowerCase() < b[sortField]?.toLowerCase()) {
      return -1;
    }
    if (a[sortField]?.toLowerCase() > b[sortField]?.toLowerCase()) {
      return 1;
    }
    return 0;
  }
  return list.sort(compare);
});

export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const scrollToRequiredFields = (errors) => {
  setTimeout(() => {
    let err = Object.keys(errors);
    const errMessages = ['Required', 'Invalid', 'Incorrect', 'Please upload a file with a maximum size of 5 MB','Closing note'];
    if (err?.length > 0) {
      let paras = document.getElementsByTagName("p");
      let requiredPara;
      for (let item of paras) {
        const errorMessage = item.innerHTML;
        if (errMessages.some(message => errorMessage.includes(message))) {
          requiredPara = item;
          break;
        }
      }
      if (requiredPara) {
        requiredPara?.scrollIntoView({
          behavior: 'smooth', block: 'center', inline: 'start',
        });
      }
    }
  }, 100);
}

export const getGraphDataSet = (title, type, widgetData) => {
  const graphColors = ['#FF31EB', '#FFC442', '#1E75FF', '#40c4ff', '#3DD598', '#ff6d00', '#795548', '#607d8b', '#d50000'];
  const getGraphData = (data, type) => {
    const graphs = ['line', 'multiLine', 'column', 'radar'];
    if (graphs.includes(type)) {
      return [{
        name: 'Widget',
        data: data?.map(v => String(v[Object.keys(v)[0]])),
        backgroundColor: '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')
      }]
    }
    return data?.map(v => String(v[Object.keys(v)[0]]));
  }

  const graphValues = {
    widgetTitle: title || '',
    type: type,
    data: type != 'text' ? [{
      data: getGraphData(widgetData, type),
      label: widgetData?.map(v => String(v[Object.keys(v)[1]])),
      backgroundColor: widgetData?.map(v => {
        const random = Math.floor(Math.random() * graphColors.length);
        return graphColors[random];
      })
    }, { graph_type: type }] : widgetData,
    dashboardWidgetId: null
  };
  return graphValues;
}

export const getLineWidgetDataSet = (widgetData, type) => {
  let lineGraphData = widgetData;
  lineGraphData = [{
    data: lineGraphData[0]?.data?.map((v, index) => {
      const firstKey = Object.keys(v)[1];
      const lastKey = Object.keys(v).pop();
      let multiLineArr = lineGraphData[0]?.data?.map(v => 0);
      multiLineArr[index] = v[firstKey];
      return {
        label: v[lastKey], backgroundColor: v?.backgroundColor,
        data: multiLineArr
      }
    }),
    label: lineGraphData[0]?.data?.map(v => v?.label)
  }, { Graph_type: type }];
  return lineGraphData;
}

export const getPriorityColors = (data) => {
  let colors = [];
  data.forEach((field) => {
    for (let value of Object.values(field)) {
      if (value == 'High') colors.push('#FF2F3E');
      else if (value == 'Medium') colors.push('#FFAF00');
      else if (value == 'Low') colors.push('#9FEF00');
      else if (value == 'Critical') colors.push('#B80505');
      else if (value == 'Severe') colors.push('#9400D3');
      else if (value == 'Moderate') colors.push('#333333');
      else if (value == 'None') colors.push('#228B22');
    }
  })
  return colors;
}

export const getAppModuleName = (path, module) => {
  const routes = path.split('/')
  if (routes[1] === 'vulnerabilityManagement') return 'vulnerabilityManagement'
  else if (routes[1] === 'incidentManagement') return 'incidentManagement'
  else if (routes[1] === 'threatIntelligence') return 'advisory'
  else if (routes[1] === 'cases') {
    if (routes[2] === 'advisory' || module === 'advisory')
      return 'caseAdvisory'
    else if (routes[2] === 'incident' || module === 'incident')
      return 'incidentManagement'
    else if (routes[2] === 'vulnerability' || module === 'vulnerability')
      return 'vulnerabilityManagement'
    else
      return 'vulnerabilityManagement'
  }
}

export const extractImageSourcesFromHTML = (htmlString) => {
  if (!htmlString) return []

  const images = htmlString.match(EXTRACT_IMAGE_ELEMENTS_FROM_HTML)
  if (!images) return []

  const urls = images.map((img) => img.replace(EXTRACT_URL_FROM_IMAGE_ELEMENT, '$1'))
  return urls
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const extractTextFromHTML = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  let text = '';

  const walk = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      text += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let child of node.childNodes) {
        walk(child);
      }
    }
  };

  walk(doc.body);
  return text;
}

export const renderHtmlOnTooltip = (text, maxLength, executeAction) => {
  const MAX_LENGTH = maxLength; // maximum number of characters to display
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  let commentText = '';

  const walk = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      commentText += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let child of node.childNodes) {
        walk(child);
      }
    }
  };

  walk(doc.body);

  // truncate comment text if necessary
  if (commentText.length > MAX_LENGTH) {
    commentText = commentText.substring(0, MAX_LENGTH) + '...';
  }

  // build final HTML string with truncated comment text and images
  let html = '';
  html += commentText;

  return html?.length >= MAX_LENGTH ?
    <Tooltip
      overlayInnerStyle={{ borderRadius: '0.5rem', maxHeight: '30vh', overflowY: 'auto' }}
      placement="topLeft"
      overlayClassName="htmlToolTip"
      title={extractTextFromHTML(text)}>
      <div onClick={executeAction} style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: html }} />
    </Tooltip> :
    <div style={{ cursor: 'pointer' }} onClick={executeAction} dangerouslySetInnerHTML={{ __html: html }} />
}

export const showNotificationTime = (timestamp, timezone) => {
  const now = moment();
  const notificationTime = moment(timestamp)?.format('DD-MM-YY hh:mm A');

  // Get the current time of particular region
  const currentDateTime = momentTimezone.tz(timezone)?.format('DD-MM-YY hh:mm A');
  const currentDate = moment(currentDateTime, 'DD-MM-YY hh:mm A');
  const commentDate = moment(notificationTime, 'DD-MM-YY hh:mm A');

  const difference = currentDate.diff(commentDate, 'minutes');

  if (difference < 1) {
    return 'Just now';
  } else if (difference < 60) {
    return `${difference} ${difference > 1 ? 'minutes' : 'minute'} ago`;
  } else if (difference < 1440) {
    const hours = Math.floor(difference / 60);
    return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`;
  } else if (difference > 1440) {

    let days = currentDate.diff(commentDate, 'days')

    return `${days} ${days > 1 ? 'days' : 'day'} ago`;
  } else {
    return moment(timestamp).format('MMM D, YYYY');
  }
}

export const exportCustomExcel = (data, columns, type) => {
  const workbook = new ExcelJS.Workbook();
  let sheet = workbook.addWorksheet("export");

  // Set the header row
  const headerRow = sheet.getRow(1);
  headerRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: '151580',
    },
  };

  // Set the font of the header cells to bold
  headerRow.font = {
    bold: true,
    color: {
      argb: 'FFFFFF',
    },
  };

  sheet.columns = columns;

  // Set alignment for number columns
  columns.forEach((column, index) => {
    sheet.getColumn(index + 1).alignment = { horizontal: 'left', vertical: 'top' };
  });

  data.forEach((row) => {
    const newRow = { ...row };
    if (Array.isArray(newRow.artifacts)) {
      const richTextValue = {
        richText: []
      };
      newRow.artifacts.forEach((artifact) => {
        const { am_name, am_value } = artifact;
        richTextValue.richText.push(
          { text: am_name + ': \n', font: { bold: true } },
          { text: am_value.replaceAll(',', '\n') + '\n' }
        );
      });
      if (type == 'incident') sheet.addRow({ ...newRow, incidentArtifact: richTextValue });
      else sheet.addRow({ ...newRow, artifacts: richTextValue });
    } else {
      sheet.addRow(newRow);
    }
  });

  workbook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet'
    })
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "export.xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  })
};

export const convertQueryStringToObject = (queryString) => {
  const keyValuePairs = queryString.split('&');
  const result = {
    search: {}
  };

  keyValuePairs.forEach(keyValuePair => {
    const [key, value] = keyValuePair.split('=');

    // Handling square brackets in the key
    const regex = /\[([^\]]+)\]/;
    const matches = regex.exec(key);

    if (matches) {
      const parsedKey = matches[1];
      result.search[parsedKey] = value;
    }
  });

  return result;
}

export const toCamelCase = (str) => {
  return str?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toUpperCase() : word.toUpperCase();
  }).replace(/\s+/g, ' ');
}

export const debounce = (func, delay) => {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
};

export const makeFilterList = (apiData) => {
  let list = [];
  list.push({ key: 'all', value: 'All', label: 'All' });
  if (typeof apiData === 'object' &&
    !Array.isArray(apiData) &&
    apiData !== null && Object.keys(apiData).length !== 0) {
    Object.entries(apiData)?.forEach(([key, value]) => {
      list.push({
        key: key,
        value: value,
        label: value,
      });
    });
  }
  return list;
}

export const addSpacesToCamelCase = (inputString) => {
  if (!inputString) return '';
  return inputString?.replace(/([a-z])([A-Z])/g, '$1 $2')?.toUpperCase();
}

export const RenderSkeleton = ({ numberOfSkeletons, numberOfRows }) => {
  let skeletons = [];
  for (let i = 0; i < numberOfSkeletons; i++) {
    skeletons.push(
      <Skeleton key={i} active height={3} paragraph={{ rows: numberOfRows }} />
    );
  };
  return <>{skeletons}</>
};

export const getDateRangeText = (dateRange) => {
  const value = parseInt(dateRange); // Extract the numeric value
  if (isNaN(value)) {
    return 'Invalid Date Range'; // Handle invalid input
  }

  let text = '';
  if (dateRange.includes('m')) {
    text = `${value} Month${value > 1 ? 's' : ''}`;
  } else if (dateRange.includes('d')) {
    text = `${value} Day${value > 1 ? 's' : ''}`;
  } else if (dateRange.includes('y')) {
    text = `${value} Year${value > 1 ? 's' : ''}`;
  } else {
    return 'Invalid Date Range'; // Handle cases without m, d, or y
  }

  return text;
};

export const renderAttachmentError = (files) => {
  let evidenceError = '';
  files.forEach((file) => {
    const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase();
    if (!allowedFileExtensions.includes(fileExtension)) {
      evidenceError = "Incorrect file type";
    } else if (file.size > 5 * 1024 * 1024) {
      evidenceError = "Please upload a file with a maximum size of 5 MB";
    }
  });
  return evidenceError;
}


export function safeDecodeURIComponent(input) {
  try {
    return decodeURIComponent(input);
  } catch (error) {
    return input;
  }
}